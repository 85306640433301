<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div :class="'pull-right'">
                    <q-btn class="mr-2" :label="!$q.screen.lt.sm ? translate('delete') : ''" no-caps dense color="danger" @click.prevent="showDeletePromotionPanel(promotion_id)" icon="delete" flat />
                    <toggle-active @closePanel="closePanel()" :no_label="$q.screen.lt.sm ? true : null" :is_active="is_active" :patch_toggle_route="patch_toggle_route" :item_id="promotion_id" :entity_type="entity_type" />
                </div>
                <div class="m-1"><q-icon name="edit" class="mr-1" />{{ translate('edit_promotion') }}</div>
            </template>
            <b-list-group class="list-group-accent overflow-hidden">
                <b-form @submit.prevent="editPromotion">
                    <b-list-group-item class="p-0 pt-2 pb-2">
                        <q-input :label="translate('name')" dense square color="primary" type="text" id="name" v-model="promotion.name" :placeholder="translate('add_promotion_enter_promotion_name')" />
                    </b-list-group-item>
                    <b-list-group-item v-if="promotion">
                        <div class="full-width row m-0 text-muted align-items-center">
                            <div v-if="promotion.target_type" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-bullseye fa-2x text-primary"></i></div>
                            <div v-if="promotion.target_type" class="col-10 col-md info-card">
                                <small>{{ translate(promotion.target_type) }}</small><br />
                                <strong class="text-primary">{{ promotion.target_name }}</strong>
                            </div>
                            <div v-if="promotion.max_usage" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-sort-amount-desc fa-2x"></i></div>
                            <div v-if="promotion.max_usage" class="col-10 col-md info-card">
                                <small>{{ translate('max_usage') }}</small><br />
                                <strong>{{ translate(promotion.max_usage) }}</strong>
                            </div>
                        </div>
                        <div v-if="promotion.valid_from || promotion.valid_until" class="full-width row m-0 text-muted align-items-center">
                            <div v-if="promotion.valid_from" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-calendar fa-2x"></i></div>
                            <div v-if="promotion.valid_from" class="col-10 col-md info-card">
                                <small>{{ translate('valid_from') }}</small><br />
                                <strong>{{ prettyDate(promotion.valid_from) }}</strong>
                            </div>
                            <div v-if="promotion.valid_until" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-calendar fa-2x"></i></div>
                            <div v-if="promotion.valid_until" class="col-10 col-md info-card">
                                <small>{{ translate('valid_until') }}</small><br />
                                <strong>{{ prettyDate(promotion.valid_until) }}</strong>
                            </div>
                        </div>
                        <div class="full-width row m-0 text-muted align-items-center">
                            <div v-if="promotion.discount_type" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-tag fa-2x"></i></div>
                            <div v-if="promotion.discount_type" class="col-10 col-md info-card">
                                <small>{{ translate('discount_type') }}</small><br />
                                <strong>{{ translate(promotion.discount_type) }}</strong>
                            </div>
                            <div v-if="promotion.promo_type" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-tag fa-2x"></i></div>
                            <div v-if="promotion.promo_type" class="col-10 col-md info-card">
                                <small>{{ translate('promo_type') }}</small><br />
                                <strong>{{ translate(promotion.promo_type) }}</strong>
                            </div>
                        </div>
                        <div class="full-width row m-0 text-muted align-items-center">
                            <div v-if="promotion.discount_value !== null" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-money fa-2x"></i></div>
                            <div v-if="promotion.discount_value !== null" class="col-10 col-md info-card">
                                <small>{{ translate('discount_value') }}</small><br />
                                <strong>
                                    {{ translate(promotion.discount_value) 
                                    + (promotion.discount_type === 'percentage' ? '%' : '') 
                                    + (promotion.discount_type === 'fixed' && currency ? ' ' + currency : '') }}
                                </strong>
                            </div>
                            <div v-if="promotion.promo_code" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-barcode fa-2x"></i></div>
                            <div v-if="promotion.promo_code" class="col-10 col-md info-card">
                                <small>{{ translate('promo_code') }}</small><br />
                                <strong>{{ promotion.promo_code }}</strong>
                            </div>
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="editService">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import DeletePromotion from './DeletePromotion'
import ToggleActive from '../../../template-parts/ToggleActive'

export default {
    name: 'EditPromotion',
    props: ['promotion_id', 'is_active', 'baseUrl', 'entity_type', 'patch_toggle_route'],
    components: {
        'toggle-active': ToggleActive,
    },
    data: function () {
        return {
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            promotion: {
                name: null,
            },
            msg: null,
        }
    },
    created: function () {
        this.getPromotion()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        showDeletePromotionPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeletePromotion,
                props: {
                    promotion_id: id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        })
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        getPromotion: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            axios.get(baseUrl + 'promotions/' + this.promotion_id, {
                    headers: headers
                })
                .then(response => {
                    this.promotion = response.data.item
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        editPromotion: function () {
            var promotion_id = this.promotion_id
            var promotion_name = this.promotion.name
            var data = {
                'name': promotion_name,
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.put(baseUrl + 'promotions/' + this.promotion_id, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'edit_promotion_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true,
                            promotion: {
                                id: promotion_id,
                                name: promotion_name
                            }
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
