<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="label" />
            </template>
            <b-list-group class="list-group-accent">
                <q-separator />
                <b-list-group-item class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
                    {{ promotion.name }}
                </b-list-group-item>
                <q-separator />
                <b-list-group-item v-if="promotion" class="list-group-item-accent-info">
                    <div class="full-width row m-0 text-muted align-items-center">
                        <div v-if="promotion.target_type" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-bullseye fa-2x"></i></div>
                        <div v-if="promotion.target_type" class="col-10 col-md info-card">
                            <small>{{ translate(promotion.target_type) }}</small><br />
                            <strong>{{ promotion.target_name }}</strong>
                        </div>
                    </div>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item v-if="promotion" class="list-group-item-accent-info">
                    <div class="full-width row m-0 text-muted align-items-center">
                        <div v-if="promotion.discount_type" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-tag fa-2x"></i></div>
                        <div v-if="promotion.discount_type" class="col-10 col-md info-card">
                            <small>{{ translate('discount_type') }}</small><br />
                            <strong>{{ translate(promotion.discount_type) }}</strong>
                        </div>
                        <div v-if="promotion.promo_type" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-tag fa-2x"></i></div>
                        <div v-if="promotion.promo_type" class="col-10 col-md info-card">
                            <small>{{ translate('promo_type') }}</small><br />
                            <strong>{{ translate(promotion.promo_type) }}</strong>
                        </div>
                    </div>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item v-if="promotion" class="list-group-item-accent-info">
                    <div class="full-width row m-0 text-muted align-items-center">
                        <div v-if="promotion.discount_value" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-money fa-2x"></i></div>
                        <div v-if="promotion.discount_value" class="col-10 col-md info-card">
                            <small>{{ translate('discount_value') }}</small><br />
                            <strong>
                                {{ translate(promotion.discount_value) 
                                + (promotion.discount_type === 'percentage' ? '%' : '') 
                                + (promotion.discount_type === 'fixed' ? ' ' + currency : '') }}
                            </strong>
                        </div>
                        <div v-if="promotion.max_usage" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-sort-amount-desc fa-2x"></i></div>
                        <div v-if="promotion.max_usage" class="col-10 col-md info-card">
                            <small>{{ translate('max_usage') }}</small><br />
                            <strong>{{ translate(promotion.max_usage) }}</strong>
                        </div>
                    </div>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item v-if="promotion && (promotion.valid_from || promotion.valid_until)" class="list-group-item-accent-info">
                    <div class="full-width row m-0 text-muted align-items-center">
                        <div v-if="promotion.valid_from" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-calendar fa-2x"></i></div>
                        <div v-if="promotion.valid_from" class="col-10 col-md info-card">
                            <small>{{ translate('valid_from') }}</small><br />
                            <strong>{{ prettyDate(promotion.valid_from) }}</strong>
                        </div>
                        <div v-if="promotion.valid_until" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-calendar fa-2x"></i></div>
                        <div v-if="promotion.valid_until" class="col-10 col-md info-card">
                            <small>{{ translate('valid_until') }}</small><br />
                            <strong>{{ prettyDate(promotion.valid_until) }}</strong>
                        </div>
                    </div>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import OffersDetailsContainer from '../../../template-parts/OffersDetailsContainerCustomer'

export default {
    name: 'PromotionDetails',
    props: ['promotion_id'],
    data() {
        return {
            currency: currency,
            promotion: {
                name: null,
            },
            msg: null,
        }
    },
    created() {
        this.getIdPromotion()
    },
    methods: {
        getIdPromotion: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            axios.get(baseUrl + 'promotions/' + this.promotion_id, {
                    headers: headers
                })
                .then(response => {
                    this.promotion = response.data.item
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel() {
            this.$emit('closePanel')
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
</style>
