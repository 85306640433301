<template>
<div class="animated fadeIn">
    <promotions-worker v-if="showPromotionsWorker" :baseUrl="baseUrl"></promotions-worker>
    <promotions-customer v-if="showPromotionsCustomer" :baseUrl="baseUrl"></promotions-customer>
</div>
</template>

<script>
import _ from 'lodash'
import PromotionsWorker from './PromotionsWorker'
import PromotionsCustomer from './PromotionsCustomer';
export default {
    name: 'Promotions',
    components: {
        'promotions-worker': PromotionsWorker,
        'promotions-customer': PromotionsCustomer
    },
    data: function () {
        return {
            baseUrl: baseUrl,
            enableCard: false,
        }
    },
    computed: {
        showPromotionsWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
        showPromotionsCustomer: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return !this.enableCard;
            }
        }
    },
}
</script>
