<template>
<div>
    <b-card class="m-0" no-body>
        <div slot="header">
            <h3>{{translate('promotions')}}</h3>
        </div>
        <search-wrapper-card :active="promotion_active" :activeStatus="activeStatus" :inactive="promotion_inactive" :inactiveStatus="inactiveStatus" form_type="promotions">
            <q-input slot="search_input" bottom-slots @input="isTyping = true" v-model="searchQuery" id="filternamepromotions" class="search-input" type="text" :placeholder="translate('find_promotion')" :dense="true">
                <template v-slot:prepend>
                    <q-icon name="search" />
                </template>
            </q-input>
            <template slot="add_button">
                <q-btn no-caps v-if="!$q.screen.lt.sm" color="primary" @click.prevent="showAddPromotionPanel">
                    <i class="fa fa-plus mr-1"></i> {{ translate('add_promotion') }}
                </q-btn>
                <q-btn dense round v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" @click.prevent="showAddPromotionPanel" />
            </template>
        </search-wrapper-card>
        <q-separator />
        <q-item class="p-0" :class="$q.screen.lt.sm ? 'flex-wrap' : ''">
            <q-item-section side class="pl-0 col-xs-6 col-md-auto bg-white">
                <q-radio :size="$q.screen.lt.sm ? '1.75rem' : ''" color="dark" @input="updateAll" v-model="filterBy" :val="'all_promotions'" :label="translate('all_sales')" text-size="0.75rem" />
            </q-item-section>
            <q-item-section side class="pl-0 col-xs-6 col-md-auto bg-white" v-if="filterBy !== 'all_promotions'">
                <q-radio :size="$q.screen.lt.sm ? '1.75rem' : ''" color="dark" @input="updateAll" v-model="filterBy" :val="'standard'" :label="translate('only_standard_promotions')" />
            </q-item-section>
            <q-item-section side class="pl-0 col-xs-6 col-md-auto bg-white" v-if="filterBy !== 'all_promotions'">
                <q-radio :size="$q.screen.lt.sm ? '1.75rem' : ''" color="dark" @input="updateAll" v-model="filterBy" :val="'promo_code'" :label="translate('only_promo_code_promotions')" />
            </q-item-section>

            <q-item-section side class="pl-0 col-xs-12 col-md-auto bg-light">
                <q-checkbox v-model="filterByTargetType" :val="false" :size="$q.screen.lt.sm ? '1.75rem' : ''" color="primary" :label="translate('target_type_promotions')" />
            </q-item-section>
            <q-item-section class="pl-0 pr-0 col-xs-12 col-md-auto" :side="!$q.screen.lt.sm" v-if="(!$q.screen.lt.sm && filterBy) || (!$q.screen.lt.sm && filterByTargetType)">
                <q-btn class="pr-2 full-height" icon="close" no-caps dense flat color="dark" @click.stop="resetAllFilters()" :val="'reset'" :label="translate('reset')" />
            </q-item-section>
        </q-item>
        <q-item v-if="filterByTargetType">
            <template >
                <q-item class="p-0">
                    <q-item-section>
                        <!-- dropdown -->
                                <q-separator></q-separator>
                        <q-select 
                            class="mt-1 mb-1" 
                            borderless 
                            :options="promotion_options" 
                            map-options
                            :label="translate('target_type_selection')" 
                            v-model="promotion_option" 
                            behavior="menu">
                            <template v-slot:prepend>
                                <q-avatar class="shadow-2 mr-1" size="38px">
                                    <q-icon name="fas fa-lg fa-tag" />
                                </q-avatar>
                            </template>
                        </q-select>
                    </q-item-section>
                </q-item>
            </template>
        </q-item>
        <q-item :class="$q.screen.lt.sm ? 'p-0' : ''" v-if="filterByTargetType && promotion_option && promotion_option.value !== 'custom_subscription'">
            <q-item-section side>
                <q-radio 
                    color="primary" 
                    v-model="filterByWhat" 
                    :val=" promotion_option.value + '_specific' " 
                    :label="translate('choose_promotions_by_' + promotion_option.value)" 
                />
            </q-item-section>
            <q-item-section side>
                <q-radio 
                    color="primary" 
                    v-model="filterByWhat" 
                    :val=" promotion_option.value + '_all' " 
                    :label="translate('choose_all_promotions_by_' + promotion_option.value)"
                    @input="updateAll"
                />
            </q-item-section>
        </q-item>
        <q-item :class="$q.screen.lt.sm ? 'p-0' : ''" v-if="filterByWhat && filterByWhat.includes('_specific')">
            <choose-target-type :target_type="filterByWhat" v-on:updateTarget="getTarget"></choose-target-type>
        </q-item>
        
    </b-card>
    <div class="list_wrapper-promotions">
        <q-item v-for="(promotion, i) in promotions" :key="'promotion-item-' + i + '-' + (i.id,promotion.id)" :id="promotion.id" class="list-item full-width draggable-item" 
            :class="promotion.is_active ? 'promotion-active' : 'promotion-inactive'">
            <q-item-section class="p-0 pl-md-2" side>
                <div class="image-thumbnail cursor-pointer" @click.prevent="showEditPromotionPanel(promotion.id, promotion.is_active)">
                    <img v-if="promotion.main_image_link" :src="promotion.main_image_link" />
                    <i v-else class="fa fa-photo fa-2x"></i>
                </div>
            </q-item-section>
            <q-item-section class="full-height align-self-center pt-2 pb-2">
                <strong class="text-muted">{{ promotion.name }} | {{ promotion_target(promotion) }}, {{ translate(promotion.discount_type) }} - {{ promotion.discount_value + ' ' + promoDiscountType(promotion.discount_type)}}</strong>
            </q-item-section>
            <!-- [+] Desktop sections. -->
            <template v-if="!$q.screen.lt.sm">
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <q-btn round icon="edit" color="primary" @click="showEditPromotionPanel(promotion.id, promotion.is_active)">
                        <q-tooltip :offset="[10, 10]">{{ translate('edit_' + entity_type) }}</q-tooltip>
                    </q-btn>
                </q-item-section>
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <q-btn round icon="delete" color="danger" @click="showDeletePromotionPanel(promotion.id)">
                        <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                    </q-btn>
                </q-item-section>
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <toggle-active :is_active="promotion.is_active" :no_label="true" :patch_toggle_route="patch_toggle_route" :item_id="promotion.id" :entity_type="entity_type" />
                </q-item-section>
            </template>
            <!-- [-] Desktop sections. -->
        </q-item>
    </div>
    <infinite-loading :identifier="'promotions-'+infPromotions" slot="append" @infinite="getPromotions" />
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'
import addPromotion from './forms/AddPromotion'
import EditPromotion from './forms/EditPromotion'
import DetailPromotion from './show/PromotionDetails'
import DeletePromotion from './forms/DeletePromotion'
import ToggleActive from '../../template-parts/ToggleActive'
import ChooseTargetType from './forms/ChooseTargetType'

export default {
    name: 'PromotionsWorker',
    components: {
        'search-wrapper-card': SearchWrapperCard,
        'toggle-active': ToggleActive,
        'choose-target-type': ChooseTargetType
    },
    data: function () {
        return {
            infPromotions: +new Date(),
            message: '',
            promotions: [],
            isTyping: false,
            promotion_active: 'active',
            promotion_inactive: '',
            promo_discount_fixed: 'fixed',
            searchQuery: "",
            order_by: '',
            show: true,
            page: 1,
            enableButon: false,
            state: {},
            filterBy: null,
            filterByTargetType: false,
            promotion_option: null,
            filterByWhat: null,
            target: null
        }
    },
    mounted: function () {
        eventBus.$on('toggle_active_promotion', (item) => {
            this.toggleActivePromotion(item)
        })
        eventBus.$on('delete_promotion', (id) => {
            this.deletePromotion(id)
        })
    },
    computed: {
        entity_type: function () {
            return 'promotion'
        },
        patch_toggle_route: function () {
            return baseUrl + 'promotions'
        },
        promotion_options: function () {
            return [
                { value: 'service', label: this.translate('service')},
                { value: 'product', label: this.translate('product')},
                { value: 'subscription', label: this.translate('sales_type_subscription')},
                { value: 'custom_subscription', label: this.translate('sales_type_custom_subscription')},
            ]
        },
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        },
        promotion_option: function (val) {
            this.filterByWhat = null;
            val && val.value === 'custom_subscription' ? this.updateAll() : null
        },
        filterByWhat: function (val) {
            if(this.filterByWhat) this.filterByWhat.includes('all')
        },
        filterByTargetType: function (val) {
            if (!val) {
                this.promotion_option = null
                this.updateAll()
            }
        }
    },
    methods: {
        getTarget: function(target) {
            this.target = { ...target }
            this.updateAll()
        },
        resetAllFilters: function () {
            this.filterBy = null
            this.filterByTargetType = false
            this.promotion_option = null
            this.filterByWhat = null

            this.updateAll()
        },
        updateAll: function () {
            this.group_id = null
            this.customer_id = null

            this.resetMainList()
        },
        promotion_target: function(promotion) {
            var target_type = promotion.target_type
            if (target_type == 'custom_subscription') {
                target_type = 'subscription'
            }
            return this.translate(target_type) + ' ' + this.translate(promotion.target_name)
        },
        updatePromotionName: function (item) {
            // Find index of specific object using findIndex method,
            var objIndex = this.promotions.findIndex((obj => obj.id === item.promotion.id))
            // and update the element from the promotions array.
            this.promotions[objIndex]['name'] = item.promotion.name ? item.promotion.name : ''
        },
        toggleActivePromotion: function (item) {
            if (item.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.promotions.findIndex((obj => obj.id === item.id))
                if (this.promotions[objIndex]) {
                    // And update is_active for the given item in list.
                    this.promotions[objIndex]['is_active'] = item.is_active ? true : false
                    eventBus.$emit('update_toggle_' + this.entity_type, item)

                    if (item.is_active === 0 && this.promotion_active === 'active' && this.promotion_inactive === '') {
                        this.deletePromotion(item.id)
                    }
                    if (item.is_active === 1 && this.promotion_active === '' && this.promotion_inactive === 'inactive') {
                        this.deletePromotion(item.id)
                    }
                }
            }
        },
        deletePromotion: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.promotions.findIndex((obj => obj.id === id))
            // and remove the element from the promotions array.
            this.promotions.splice(objIndex, 1)
        },
        resetMainList: function () {
            this.page = 1
            this.promotions = []
            this.infPromotions++
        },
        activeStatus: _.debounce(function () {
            if (this.promotion_active === 'active') {
                this.promotion_active = ''
            } else {
                this.promotion_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.promotion_inactive === 'inactive') {
                this.promotion_inactive = ''
            } else {
                this.promotion_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
        
        getPromotionsUrl: function () {
            var promotionsUrl = baseUrl +
                'promotions?page=' + this.page +
                '&items_per_page=' + this.$items_per_page + 
                (this.filterBy && this.filterBy !=='all_promotions' ? '&only_'+ this.filterBy +'=1' : '') +
                (this.promotion_option ? '&target_type=' + this.promotion_option.value : '') +
                (this.filterByWhat && !this.filterByWhat.includes('all') ? '&target_id=' + this.target.id : '') +

                (this.searchQuery ? '&search=' + this.searchQuery : '')
            // var saleUrl = baseUrl + 'sales?page=' + this.page + '&items_per_page=' + this.$items_per_page +
            //     (this.customer_id ? '&customer_id=' + this.customer_id : '') +
            //     (this.group_id ? '&group_id=' + this.group_id : '') +
            //     (this.orderBy ? '&order_by=' + this.orderBy + (this.orderByDirection !== null ? this.orderByDirection : '') : '') +
            //     (this.filterBy && this.filterBy === 'deleted' ? '&deleted=1' : '') +
            //     (this.buyer_type ? '&buyer_type=' + this.buyer_type : '') +
            //     (this.payment_status && this.payment_status.value ? '&payment_status=' + this.payment_status.value : '') +
            //     (this.sale_from_date && this.sale_until_date ? '&from_date=' + this.sale_from_date + '&until_date=' + this.sale_until_date : '')

            if (this.promotion_active === 'active' && this.promotion_inactive === '') {
                promotionsUrl += '&promotion_status=' + this.promotion_active
            } 
            if (this.promotion_active === '' && this.promotion_inactive === 'inactive') {
                promotionsUrl += '&promotion_status=' + this.promotion_inactive
            }
            
            return promotionsUrl
        },
        getPromotions: function ($state) {
            var url = this.getPromotionsUrl()

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let promotions = this.promotions
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state

                    if (response.data.items && response.data.items.length > 0) {
                        promotions.push(...response.data.items)
                    } 
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.promotions = promotions
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        promoDiscountType: function(type) {
            return this.promo_discount_fixed == type ? 'lei' : '%'
        },
        showDeletePromotionPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeletePromotion,
                props: {
                    promotion_id: id
                }
            })
        },
        showAddPromotionPanel: function () {
            const panelInstance = this.$showPanel({
                component: addPromotion,
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        showEditPromotionPanel: function (id, is_active) {
            const panelInstance = this.$showPanel({
                component: EditPromotion,
                props: {
                    promotion_id: id,
                    entity_type: this.entity_type,
                    patch_toggle_route: this.patch_toggle_route,
                    is_active: is_active
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updatePromotionName(result)
                    }
                })
        },
        showDetailPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DetailPromotion,
                props: {
                    promotion_id: id
                }
            })

        },
    }
}
</script>

<style lang="scss" scoped>
.search-input {
    margin-left: -15px !important;
}
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}

.promotion-active {
    border-left: 2px solid var(--q-color-primary)!important;
}
.promotion-inactive {
    border-left: 2px solid var(--q-color-dark)!important;
}
</style>
