<template>
<div class="animated fadeIn">
    <b-card class="mb-2" no-body>
        <div slot="header">
            <h3>{{translate('promotions')}}</h3>
        </div>
    </b-card>
    <div class="row d-flex list_wrapper-promotions promotions-list">
        <div v-for="(promotion,i) in promotions" :key="'promotion-' + i + '-' + (i.id, promotion.id)" href="#" class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-5ths p-2">
            <q-item clickable hoverable class="list-item">
                <q-item-section><strong>{{ promotion.name }}</strong></q-item-section>
                <q-item-section side>
                    <q-btn round flat color="primary" @click="showDetailPanel(promotion.id)" :title="translate('details')">
                        <i class="fas fa-lg fa-info-circle"></i>
                    </q-btn>
                </q-item-section>
            </q-item>
        </div>
    </div>
    <infinite-loading :identifier="'promotions-'+infPromotions" slot="append" @infinite="getPromotions" />
</div>
</template>

<script>
import _ from 'lodash'
import DetailPromotion from './show/PromotionDetails'

export default {
    name: 'PromotionsCustomer',
    data: function () {
        return {
            infPromotions: +new Date(),
            promotions: [],
            promotion_active: 'active',
            promotion_inactive: '',
            page: 1,
            state: {}
        }
    },
    methods: {
        getPromotions: function ($state) {
            if (this.promotion_active === 'active' && this.promotion_inactive === 'inactive') {
                var promotionUrl = baseUrl +
                    'promotions?page=' + this.page +
                    '&items_per_page=' + this.$items_per_page +
                    '&promotion_status=' + this.promotion_active +
                    ',' + this.promotion_inactive
            } else if (this.promotion_active === 'active' && this.promotion_inactive === '') {
                var promotionUrl = baseUrl +
                    'promotions?page=' + this.page +
                    '&items_per_page=' + this.$items_per_page +
                    '&promotion_status=' + this.promotion_active
            } else if (this.promotion_active === '' && this.promotion_inactive === 'inactive') {
                var promotionUrl = baseUrl +
                    'promotions?page=' + this.page +
                    '&items_per_page=' + this.$items_per_page +
                    '&promotion_status=' + this.promotion_inactive
            } else if (this.promotion_active === '' && this.promotion_inactive === '') {
                this.promotions = []
                $state.complete()
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(promotionUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.promotions.push(...response.data.items)
                    } 
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDetailPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DetailPromotion,
                props: {
                    promotion_id: id
                }
            })

            panelInstance.promise
                .then(result => {
                    this.page = 1
                    this.promotions = []
                    return this.getPromotions(this.state)
                })
        },
    }
}
</script>
<style lang="scss" scoped>
.q-item.list-item {
    width: 100%;
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
.list_wrapper-promotions {
    margin-left: -8px;
    margin-right: -8px;
    
    &.promotions-list {
        width: calc(100% + 16px);
    }
}
</style>