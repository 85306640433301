<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="info" />
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase small">
                    {{ item.name }}
                </b-list-group-item>
                <b-list-group-item v-if="item" class="p-0 m-0 mt-0 list-group-item-accent-info">
                    <template v-if="item.images && item.images.length">
                        <div class="m-0 p-0 item-detail-images">
                            <q-carousel 
                                swipeable animated v-model="slide" 
                                :arrows="item.images.length > 1 ? true : false" 
                                :navigation="item.images.length > 1 ? true : false" 
                                infinite ref="carousel" :disableEscClick="false" :fullscreen.sync="fullscreen">
                                <q-carousel-slide class="p-0 slide-main-image-container" v-for="(image,i) in item.images" :key="'itemdetail-image-' + item_id + '-' + i" @click="fullscreen = !fullscreen" :name="(i+1)">
                                    <img :src="baseUrl + image.image_name" class="slide-main-image" />
                                </q-carousel-slide>
                            </q-carousel>
                        </div>
                    </template>
                </b-list-group-item>
                <b-list-group-item v-if="item" class="mt-0 list-group-item-accent-info">
                    <div v-if="item.description" class="item-description">{{ item.description }}</div>
                    <div v-if="item.price">{{translate('price')}}: {{ item.price }}<template v-if="currency"> {{ currency }}</template></div>
                    <div v-if="item.access_price">{{translate('access_price')}}: {{ item.access_price }}<template v-if="currency"> {{ currency }}</template></div>
                    <div v-if="item.full_price">{{translate('full_price')}}: {{ item.full_price }}<template v-if="currency"> {{ currency }}</template></div>
                    <div v-if="item.base_price">{{translate('basic_price')}}: {{ item.base_price }}<template v-if="currency"> {{ currency }}</template></div>
                    <div v-if="item.subscription_price">{{translate('subscription_price')}}: {{ item.subscription_price }}<template v-if="currency"> {{ currency }}</template></div>
                    <div v-if="item.quantities">{{translate('quantities')}}: {{item.quantities}}</div>
                    <div v-if="item.valid_from">{{translate('valid_from')}}: {{ item.valid_from }}</div>
                </b-list-group-item>
            </b-list-group>
            <slot name="list_subscriptions" />
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
export default {
    name: 'OffersDetailsContainer',
    props: ['item', 'baseUrl', 'item_id', 'closePanel'],
    data: function () {
        return {
            slide: 1,
            fullscreen: false,
            currency: currency
        }
    }
}
</script>

<style lang="scss" scoped>
.item-detail-images {
    margin-top: -12px;
    margin-left: -20px;
    margin-right: -20px;
}

.item-description {
    margin-bottom: 20px;
}

.q-carousel.q-panel-parent.fullscreen {
    background-color: rgba(0, 0, 0, .75);
    padding: .5rem;

    .q-carousel__slide {
        background-size: contain;
        background-repeat: no-repeat;
    }
}
</style>
