<!-- [+] Show and filter Customers. -->
<template>
<div class="fit choose-customer-container">
    <q-item class="full-width" clickable @click="toggleTargets()">
        <q-item-section avatar>
            <q-avatar class="cursor-pointer shadow-2">
                <img v-if="selected_target && selected_target.image_link" :src="selected_target.image_link">
                <i v-else class="fas fa-user fa-1x text-muted"></i>
            </q-avatar>
        </q-item-section>
        <q-item-section>
            <span class="text-muted">{{translate('selected_' + target_received)}}</span>
            <h4 class="mb-0 align-self-start cursor-pointer selected-customer-name">{{ selected_target && selected_target.name ? selected_target.name : translate('choose_promotions_by_' + target_received) }}</h4>
        </q-item-section>
        <q-item-section v-if="selected_target && selected_target.name" side>
            <q-btn stack flat @click.stop="cleanSelection()" :title="translate('cancel_selection')">
                <i class="fas fa-close"></i>
            </q-btn>
        </q-item-section>
    </q-item>
    <div v-if="showTargets" class="position-relative p-0 no-focus-style">
        <q-separator />
        <div class="row m-0 align-items-center">
            <div class="col-xs-12 text-right">
                <q-input class="search-input" @input="isTypingTarget = true" v-model="searchTarget" id="filternametarget" type="text" :placeholder="translate('choose_promotions_by_' + target_received)" :dense="true">
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                </q-input>
            </div>
        </div>
        <b-card-body class="bg-light m-0 pt-0 pb-0 customers-list-container">
            <div class="card_scroll-customers">
                <div class="wrapper-customers-list">
                    <q-item class="full-width list-item" :class="selected_target && selected_target.name && target.name === selected_target.name ? 'bg-primary' : ''" tag="label" clickable v-ripple v-for="(target,i) in targets" :key="'target-' + i + '-' + target.id">
                        <q-item-section avatar>
                            <q-avatar class="shadow-2">
                                <img v-if="target.image_link" :src="target.image_link">
                                <i v-else class="fas fa-user fa-1x text-muted"></i>
                            </q-avatar>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ target.name }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-radio emit-value @input="setSelectedTarget(target.id, target.name)" size="md" flat color="primary" :id="target.id" :val="target" v-model="selected_target" />
                        </q-item-section>
                    </q-item>
                </div>
                <infinite-loading :identifier="infTargets" slot="append" @infinite="getTargets" />
            </div>
            <q-item class="p-0" style="min-height: 0;">
                <q-item-section>
                </q-item-section>
                <q-item-section side>
                    <q-btn @click.stop="toggleTargets()" flat no-caps text-color="dark" color="light" v-if="showTargets" class="pull-right">{{ translate('hide_list') }} <q-icon name="fa fa-angle-up" /></q-btn>
                </q-item-section>
            </q-item>
        </b-card-body>
    </div>
</div>
</template>
<!-- [-] Show and filter Customers. -->

<script>
import { eventBus } from '../../../../main'

export default {
    name: 'ChooseCustomer',
    watch: {
        searchTarget: _.debounce(function () {
            this.isTypingTarget = false
        }, 200),
        isTypingTarget: function (value) {
            if (!value) {
                this.resetMainList()
            }
        },
    },
    props: ['target_type'],
    data: function () {
        return {
            showTargets: false,
            state: {},
            infTargets: +new Date(),
            targets: [],
            page_target: 1,
            isTypingTarget: false,
            searchTarget: '',
            target_active: 'active',
            selected_target: {
                name: ''
            },
            schedule: {},
        }
    },
    computed: {
        target_received: function () {
            return this.target_type.split('_')[0]
        }
    },
    methods: {
        cleanSelection: function () {
            this.selected_target = null
            this.$emit('updateTarget', {'id': 0, 'name': ''})
            eventBus.$emit('updateTarget', {'id': 0, 'name': ''})
        },
        toggleTargets: function () {
            this.showTargets = !this.showTargets
            this.resetMainList()
        },
        hideTargets: function () {
            this.showTargets = false
        },
        resetMainList: function () {
            this.page_target = 1
            this.targets = []
            this.infTargets++
        },
        setSelectedTarget: function (target_id, target_name) {
            if (target_id) {
                this.selected_target.id = target_id
                this.selected_target.name = target_name
                this.$emit('updateTarget', {'id': target_id, 'name': target_name})
                eventBus.$emit('updateTarget', {'id': target_id, 'name': target_name})
            }
            this.showTargets = false
            return false
        },
        getTargets: function ($state) {
            const target = this.target_received + 's?'
            const targetBuild = target === 'products' ? target : `promotions/${target}`

            const url = baseUrl + targetBuild + 'page=' + this.page_target +
                '&items_per_page=' + this.$items_per_page +
                (this.searchTarget.length > 0 ? '&search=' + this.searchTarget : '')

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            var options = []
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let targets = this.targets
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        targets.push(...response.data.items.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page_target++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.targets = targets
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })

        },
        targetId: function () {
            var id = 0
            if (this.selected_target && this.selected_target.id) {
                id = this.selected_target.id
            }
            return id
        },
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }

    &.selected-customer {
        border-color: var(--primary)!important;
    }
}
body .search-input {
    margin-left: 15px !important;
    margin-right: 15px !important;
    max-width: calc(100% - 30px);
}
@media screen and (max-width: 767px) {
    body .search-input {
        margin-top: 10px;
        margin-left: 15px !important;
        margin-right: 0 !important;
    }
    .card_scroll-customers {
        max-height: calc(280px + 3.75rem);
    }
}
.customers-list-container {
    position: absolute;
    width: 100%;
    left: 0;
    top: auto;
    z-index: 1000;
    border: 1px solid rgba(0, 0, 0, .1);
    border-top: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .1);

    .card_scroll-customers {
        overflow-x: hidden;
        max-height: 50vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
</style>
